<script>
export default {
  name: 'Loading',
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div
    class="loading"
    :class="{'theme-dark': dark}"
  >
    <span class="loading-point loading-point-1" />
    <span class="loading-point loading-point-2" />
    <span class="loading-point loading-point-3" />
  </div>
</template>

<style src="@/assets/styles/themes/default/loading.css"></style>
